const base = require('base/product/base');
const focusHelper = require('base/components/focus');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>'}${data.valid.message}</div>`;

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append(`${'<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>'}${data.resources.emptyCartMsg}</h1> ` +
                '</div> ' +
                '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity .text').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems,
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * remove item from cart
 * @param {*} el - jq element of product card to be removed
 */
function removeItem(el) {
    const group = el.closest('.cartgroup');
    el.remove();
    const qty = group.find('.product-info.card').length;
    if (qty > 0) {
        group.find('.groupqty').html(qty);
    } else {
        group.find('.product-info.header').remove();
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems,
    });
    $('.minicart-quantity .text').empty().append(data.numItems);

        //.minicart-subtotal actualization
        var subTotalIncoming = data.totals.subTotal;
        var subTotalString = String(subTotalIncoming);
        var cleanedSubtotal = subTotalString
            .replace(/[^0-9.,]/g, '')
            .replace(',', '.');
        var subTotalValue = parseFloat(cleanedSubtotal);
        var formattedSubtotal = '';
        if (!isNaN(subTotalValue)) {
            formattedSubtotal = new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
            }).format(subTotalValue);
            formattedSubtotal = formattedSubtotal.replace(/\s/g, '');
        } else {
            console.error("El subtotal no es un número válido:", cleanedSubtotal);
        }
    $('.minicart .minicart-subtotal .text').text(formattedSubtotal);

    if (document.getElementsByClassName('original-auth-total').length > 0) {
        const totalPriceEle = document.getElementsByClassName('original-auth-total')[0];
        const origTotalPriceString = totalPriceEle.innerText;
        const origTotalPrice = parseFloat(origTotalPriceString.substr(1));
        const saveEditBtn = document.getElementById('editOrderSaveBtn');
        const paymentEditBTn = document.getElementById('editOrderPaymentBtn');

        if (parseFloat(data.totals.grandTotal.substr(1), 10) > parseFloat(origTotalPrice, 10)) {
            saveEditBtn.style.display = 'none';
            paymentEditBTn.style.display = 'block';
        } else {
            saveEditBtn.style.display = 'block';
            paymentEditBTn.style.display = 'none';
        }
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append(`- ${data.totals.orderLevelDiscountTotal.formatted}`);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append(`- ${
            data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach((item) => {
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        }

        if (item.priceTotal && item.priceTotal.renderedPrice) {
            var red = $(`.item-total-${item.UUID}`).hasClass('red') || $(`.item-total-${item.UUID}`).first().children().first().hasClass('red');
            $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
            if (red){
                $(`.item-total-${item.UUID}`).first().children().first().addClass('red');
            }
        }

        if (item.quantity){
            var variableWeight = $(`.itemquantity-${item.id} .quantity`).closest('.add-to-cart').data('variable-weight');
            var element = $(`.itemquantity-${item.id} .quantity`).filter(function() {
                return !$(this).closest('.wishlist-product-info-table').length;
            });
            if (variableWeight) {
                element.empty().append((Math.round((item.quantity * item.unitData.value)*10000)/10000).toString().replace('.',','));        
            } else {
                element.empty().append(item.quantity);        
            }    
        }
    });

    $('.groupqty').each(function (index, value) {
        if (data.groupedItems) {
            const items = data.groupedItems;
            let groupExists = false;
            for (let i = 0; i < items.length; i += 1) {
                const {
                    displayName,
                } = items[i];

                var prodsQuantity = 0;
                for (var j = 0; j < items[i].items.length; j++) {
                    if (items[i].items[j].unitData && items[i].items[j].unitData.unit === 'kg') {
                        prodsQuantity++;
                    } else {
                        prodsQuantity += items[i].items[j].quantity;
                    }
                }

                if (this.parentNode.innerText.indexOf(displayName) !== -1) {
                    value.innerText = prodsQuantity;
                    groupExists = true;
                    break;
                }
            }
            if (!groupExists) {
                // if there is no more group inside the cart data clear the related DOM elements
                this.parentNode.remove();
            }
        }
    });

    updateInfoNumberOfProducts(data);
    updateMiniCartNumber(data);
}

/*
 * Update the mini-cart counter

 */
function updateMiniCartNumber(data){
    if (data && $.isNumeric(data.quantityTotal)) {
        $('.minicart .minicart-quantity .text').text(data.quantityTotal);
    }

    var prodsQuantity = 0;
    if (data && data.items) {
        const items = data.items;

        for (var i = 0; i < items.length; i++) {
            if (items[i].unitData && items[i].unitData.unit === 'kg') {
                prodsQuantity++;
            } else {
                prodsQuantity += items[i].quantity;
            }
        }
        $('.minicart .minicart-quantity .text').text(prodsQuantity);
    } else if(data && data.cart && data.cart.items) {
        const items = data.cart.items;

        for (var i = 0; i < items.length; i++) {
            if (items[i].unitData && items[i].unitData.unit === 'kg') {
                prodsQuantity++;
            } else {
                prodsQuantity += items[i].quantity;
            }
        }
        $('.minicart .minicart-quantity .text').text(prodsQuantity);
    }
}

/**
 * Update the text with the number of items on the basket
 * 
 */
function updateInfoNumberOfProducts(data){

    var prodsQuantity = 0;
    if (data && data.items) {
        const items = data.items;

        for (var i = 0; i < items.length; i++) {
            if (items[i].unitData && items[i].unitData.unit === 'kg') {
                prodsQuantity++;
            } else {
                prodsQuantity += items[i].quantity;
            }
        }
    } else if(data && data.cart && data.cart.items) {
        const items = data.cart.items;

        for (var i = 0; i < items.length; i++) {
            if (items[i].unitData && items[i].unitData.unit === 'kg') {
                prodsQuantity++;
            } else {
                prodsQuantity += items[i].quantity;
            }
        }
    }


    if ($('.cart-info').length){
        if(prodsQuantity > 1){
            // Get the element p of the classes info-no-items and info-one-item and ensure that the 
            // style has diplay: none. Do it adding the style inside the html tag
            $('.info-no-items p').css('display', 'none');
            $('.info-one-item p').css('display', 'none');
            // Get the text of the first element p of the class info-more-items, create an string
            // with this "replace('{0}', data.numItems)" then, replace the text of the second p 
            // element and ensure that the element doesn't have a display none. Do it adding the style inside the html tag
            var infoNumberMoreProduct = $('.info-more-items p:first').text();
            $('.info-more-items p:last').text(infoNumberMoreProduct.replace('{0}', prodsQuantity)).css('display', '');
        }else if(prodsQuantity == 1) {
            //Get the element p of the class info-no-items and ensure that the 
            // style has diplay: none. Do it adding the style inside the html tag. Get the element p of the class info-one-item and
            // ensure that the element doesn't have a display none. Do it adding the style inside the html tag. Get the p element inside
            // the info-more-elements and make it diplsay none. Do it adding the style inside the html tag
            $('.info-no-items p').css('display', 'none');
            $('.info-one-item p').css('display', '');
            $('.info-more-items p:last').css('display', 'none');
        } else {
            //Get the element p of the class info-no-items and ensure that the 
            // style doesn't have diplay: none, get the element p of the class info-one-item and
            // ensure that the element has a display none and get the p element inside
            // the info-more-elements and make it diplsay none.
            $('.info-no-items p').css('display', '');
            $('.info-one-item p').css('display', 'none');
            $('.info-more-items p:last').css('display', 'none');
        }
    }else {
        //Get the element p of the class info-no-items and ensure that the 
        // style doesn't have diplay: none, get the element p of the class info-one-item and
        // ensure that the element has a display none and get the p element inside
        // the info-more-elements and make it diplsay none.
        $('.info-no-items p').css('display', '');
        $('.info-one-item p').css('display', 'none');
        $('.info-more-items p:last').css('display', 'none');
    }
    
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>'}${message}</div>`;

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount text-center">${
                item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (let i = 0; i < data.items.length; i += 1) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $(`.availability-${lineItem.UUID}`).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach((message) => {
                messages += `<p class="line-item-attributes">${message}</p>`;
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += `<p class="line-item-attributes line-item-instock-date">${
                lineItem.availability.inStockDate
            }</p>`;
        }
    }

    $(`.availability-${lineItem.UUID}`).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (let i = 0, l = array.length; i < l; i += 1) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates the quantity of product in the cart.
 * @param {string} url - Url for handling the update
 * @param {string} uuid - UUID of the lineitem
 * @param {number} quantity - the quantity to set
 * @param {number} previousQuantity - the quantity previously selected
 * @param {number} value - the value of variable weight
 */
function updateCartQuantity(url, uuid, quantity, previousQuantity, variableWeightValue, variableWeight) {
    $(this).parents('.card').spinner().start();

    $.ajax({
        url,
        type: 'get',
        context: this,
        dataType: 'json',
        success(data) {
            // Updates selects and + - buttons
            if (variableWeight) {
                var roundedPrice = (quantity * variableWeightValue).toFixed(2);
                $(`.quantity[data-uuid="${uuid}"]`).val(roundedPrice.toString().replace('.', ','));
                $(`.add-to-cart[data-uuid="${uuid}"]`).find('.update-quantity .quantity').html(roundedPrice.toString().replace('.', ','));
            }    
            $(this).data('pre-select-qty', quantity);
            $(`.add-to-cart[data-uuid="${uuid}"]`).data('quantity', quantity);

            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            updateAvailability(data, uuid);
            validateBasket(data);
            updateMiniCartNumber(data);
            const {
                iteminfos,
            } = data;
            $('.cart-json').data('cart', iteminfos);
            $('body').trigger('cart:update');

            $.spinner().stop();
            if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        },
        error(err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                if (previousQuantity) {
                    $(this).val(parseInt(previousQuantity, 10));
                }
                $.spinner().stop();
            }
        },
    });
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    const lineItem = findItem(data.cartModel.items, (item) => item.UUID === uuid);

    if (lineItem.variationAttributes) {
        const colorAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'color');

        if (colorAttr) {
            const colorSelector = `.Color-${uuid}`;
            const newColor = `Color: ${colorAttr.displayValue}`;
            $(colorSelector).text(newColor);
        }

        const sizeAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'size');

        if (sizeAttr) {
            const sizeSelector = `.Size-${uuid}`;
            const newSize = `Size: ${sizeAttr.displayValue}`;
            $(sizeSelector).text(newSize);
        }

        const imageSelector = `.card.product-info.uuid-${uuid} .item-image > img`;
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        const option = lineItem.options[0];
        const optSelector = `.lineItem-options-values[data-option-id="${option.optionId}"]`;
        $(optSelector).data('value-id', option.selectedValueId);
        $(`${optSelector} .line-item-attributes`).text(option.displayName);
    }

    const qtySelector = `.quantity[data-uuid="${uuid}"]`;
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $(`.remove-product[data-uuid="${uuid}"]`).data('pid', data.newProductId);

    const priceSelector = `.line-item-price-${uuid} .sales .value`;
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        const listPriceSelector = `.line-item-price-${uuid} .list .value`;
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    const htmlString = '<!-- Modal -->' +
        '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.product-quickview');
    const footer = $html.find('.modal-footer').children();

    return {
        body,
        footer,
    };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success(data) {
            const parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

function confirmAllDelete(actionUrl) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    $deleteConfirmBtn.data('action', actionUrl);

    const $productToRemoveSpan = $('.product-to-remove');
}

module.exports = function () {
    $('body').on('change', '.allow-replacement', function (e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const uuid = $(this).data('uuid');
        let val = 'false';
        if ($(this).is(':checked')) {
            val = 'true';
        } else {
            val = 'false';
        }
        let urlParams;
        if (productID && uuid) {
            urlParams = {
                pid: productID,
                uuid,
                val,
            };
        } else {
            urlParams = {
                val,
            };
        }

        const url = appendToUrl(actionUrl, urlParams);

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success() {
                if (uuid && val === 'false') {
                    $('#isReplacementAllowed').prop('checked', false);
                } else if (uuid && val === 'true') {
                    const checkboxes = $('input[type=checkbox][data-uuid]').length;
                    const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
                    if (checkboxes === checkedboxes) {
                        $('#isReplacementAllowed').prop('checked', true);
                    }
                } else if (!uuid && val === 'true') {
                    $('input[type=checkbox][data-uuid]').prop('checked', true);
                } else {
                    $('input[type=checkbox][data-uuid]').prop('checked', false);
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const productName = $(this).data('name');
        const uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('click', '.remove-all-products', function (e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        confirmAllDelete(actionUrl);
    });

    $('body').on('afterRemoveFromCart', (e, data) => {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click((e) => {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const urlParams = {
            pid: productID,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center empty-cart-container"> ' +
                        '</div> ' +
                        '</div>');
                    var emptyImage = $('.info-number-items img').css('display', '');
                    var emptyLink = $('.info-number-items .empty-cart-button').css('display', '');
                    var emptyText = $('.info-empty-cart-text').css('display', '');
                    $('.cart .empty-cart-container').append(emptyImage);
                    $('.cart .empty-cart-container').append(emptyText);
                    $('.cart .empty-cart-container').append(emptyLink);
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity .text').empty().append(data.basket.numItems);
                    // .minicart-subtotal actualization
                        var subTotalIncoming = '0';
                        if (data && data.totals && data.totals.subTotal) {
                            subTotalIncoming = data.totals.subTotal;
                        }
                        var subTotalString = String(subTotalIncoming);
                        var cleanedSubtotal = subTotalString
                            .replace(/[^0-9.,]/g, '')
                            .replace(',', '.');
                        var subTotalValue = parseFloat(cleanedSubtotal);
                        var formattedSubtotal = '';
                        if (!isNaN(subTotalValue)) {
                            formattedSubtotal = new Intl.NumberFormat('es-ES', {
                                style: 'currency',
                                currency: 'EUR',
                            }).format(subTotalValue);
                        } else {
                            console.error("El subtotal no es un número válido:", cleanedSubtotal);
                            formattedSubtotal = '0€';
                        }
                    $('.minicart .minicart-subtotal .text').text(formattedSubtotal);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems,
                    });
                    updateInfoNumberOfProducts(false);
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (let i = 0; i < data.toBeDeletedUUIDs.length; i += 1) {
                            $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                        }
                    }
                    removeItem($(`.uuid-${uuid}`));
                    $(`.uuid-${uuid}`).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                    updateMiniCartNumber(data.basket);
                }
                const {
                    iteminfos,
                } = data;
                $('.cart-json').data('cart', iteminfos);

                $('body').trigger('cart:update');

                // If after removal of productLineItem all items have been selected check the Allow Replacement button for entire basket
                const checkboxes = $('input[type=checkbox][data-uuid]').length;
                const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
                if (checkboxes === checkedboxes) {
                    $('#isReplacementAllowed').prop('checked', true);
                }

                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('body').on('click', '.add-to-cart button.cart-update-quantity', function () {
        const productID = $(this).closest('.add-to-cart').data('pid');
        const currentQuantity = parseInt($(this).closest('.add-to-cart').data('quantity'), 10);
        var variableWeight = $(this).closest('.add-to-cart').data('variable-weight');
        var variableWeightValue = $(this).closest('.add-to-cart').data('variable-weight-value');
        const uuid = $(this).closest('.add-to-cart').data('uuid');
        let url = $(this).closest('.add-to-cart').data('action');
        const decrease = $(this).data('decrease');

        const targetQuantity = decrease ? currentQuantity - 1 : currentQuantity + 1;
        if (targetQuantity <= 0) {
            // Should use remove button to delete
            return;
        }

        const urlParams = {
            pid: productID,
            quantity: targetQuantity,
            uuid,
        };
        url = appendToUrl(url, urlParams);
        updateCartQuantity(url, uuid, targetQuantity, currentQuantity, variableWeightValue, variableWeight);
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        const currentQuantity = $(this).data('pre-select-qty');
        const targetQuantity = $(this).val();
        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');

        const urlParams = {
            pid: productID,
            quantity: targetQuantity,
            uuid,
        };
        url = appendToUrl(url, urlParams);

        updateCartQuantity(url, uuid, targetQuantity, currentQuantity);
    });

    $('.shippingMethods').change(function () {
        const url = $(this).attr('data-actionUrl');
        const urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id'),
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success(data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    updateMiniCartNumber(data);
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('.promo-code-form').submit((e) => {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        const $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    updateMiniCartNumber(data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            },
        });
        return false;
    });

    $('.promo-code-form-desktop').submit((e) => {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field-desktop').val()) {
            $('.promo-code-form-desktop .form-control').addClass('is-invalid');
            $('.promo-code-form-desktop .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        const $form = $('.promo-code-form-desktop');
        $('.promo-code-form-desktop .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.error) {
                    $('.promo-code-form-desktop .form-control').addClass('is-invalid');
                    $('.promo-code-form-desktop .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    updateMiniCartNumber(data);
                }
                $('.coupon-code-field-desktop').val('');
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            },
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        const couponCode = $(this).data('code');
        const uuid = $(this).data('uuid');
        const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        const $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const couponCode = $(this).data('code');
        const urlParams = {
            code: couponCode,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                $(`.coupon-uuid-${uuid}`).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                updateMiniCartNumber(data);
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success(data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            },
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', (e) => {
        const focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select',
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', (e, response) => {
        // update global add to cart (single products, bundles)
        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order') ||
            !$('.global-availability', dialog).data('available'),
        );
    });

    $('body').on('product:updateAvailability', (e, response) => {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            const allAvailable = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('available'));

            const allReady = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('ready-to-order'));

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', (e, response) => {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        const selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        const selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        const updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        const selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        const selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        const uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        const form = {
            uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId,
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success(data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(`.uuid-${data.uuidToBeDeleted}`).remove();
                    }

                    validateBasket(data.cartModel);
                    updateMiniCartNumber(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        }
    });
    $(document).ready(function () {
        // Check if carousel elements are loaded
        const checkExist = setInterval(function () {
            if ($('.carousel-wrapper').length && $('.carousel-slide').length) {
                clearInterval(checkExist); // Stop checking once elements are found
                initializeCarousel();
            }
        }, 100); // Check every 100 milliseconds
    
        // Carousel Initialization Function
        function initializeCarousel() {
            const $slides = $('.carousel-slide');
            const $carouselSlidesParent = $slides.first().parent();
    
            // Función para manejar la transición del contenedor
            function animateCarousel(direction) {
                const slideWidth = $slides.outerWidth(true); // Ancho de una slide

                $('.carousel-button').prop('disabled', true); // Deshabilitar botón del carousel
    
                if (direction === 'next') {
                    // Mover contenedor hacia la izquierda
                    $carouselSlidesParent.css({
                        transition: 'transform 0.5s ease',
                        transform: `translateX(-${slideWidth}px)`,
                    });
    
                    // Después de la transición, reorganizar las slides
                    setTimeout(() => {
                        $carouselSlidesParent.css('transition', 'none'); // Desactivar transición temporalmente
                        const $firstSlide = $carouselSlidesParent.find('.carousel-slide').first();
                        $carouselSlidesParent.append($firstSlide); // Mover primera slide al final
                        $carouselSlidesParent.css('transform', 'translateX(0)'); // Restablecer posición
                        $('.carousel-button').prop('disabled', false); // Habilitar botón del carousel
                    }, 500); // Tiempo de la animación
                } else if (direction === 'prev') {
                    // Mover última slide al principio antes de la transición
                    const $lastSlide = $carouselSlidesParent.find('.carousel-slide').last();
                    $carouselSlidesParent.prepend($lastSlide);
                    $carouselSlidesParent.css({
                        transition: 'none',
                        transform: `translateX(-${slideWidth}px)`,
                    });
    
                    // Luego, mover el contenedor de vuelta a la posición inicial con transición
                    setTimeout(() => {
                        $carouselSlidesParent.css({
                            transition: 'transform 0.5s ease',
                            transform: 'translateX(0)',
                        });
                        $('.carousel-button').prop('disabled', false); // Habilitar botón del carousel
                    }, 0); // Iniciar transición inmediatamente
                }
            }
    
            // Next button functionality
            $('.carousel-button-next').on('click', function () {
                animateCarousel('next');
            });
    
            // Previous button functionality
            $('.carousel-button-prev').on('click', function () {
                animateCarousel('prev');
            });
    
            // Ajustar carrusel en redimensionamiento de ventana
            $(window).resize(function () {
                const slideWidth = $slides.outerWidth(true);
                $carouselSlidesParent.css('transform', 'translateX(0)');
            });
        }
    });
    

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
