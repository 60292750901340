/**
 * showSelectStoreModal
 */
function showSelectStoreModal() {
    $('#selectStoreModal').addClass('d-flex');
    $('#selectStoreModal').modal({ keyboard: false });
    $('#btn-noStore').click(() => {
        const url = $('#btn-noStore').data('action');

        $.ajax({
            url,
        }).done(() => {
            // redirect to homepage
            const redirectUrl = $('#btn-noStore').data('redirecturl');
            window.location.href = redirectUrl;
        });
    });
}

/**
 * drawSelectStoreModal
 * Modal Dialog to let shopper select a store
 */
function drawSelectStoreModal(checkout) {
    var url = $('.headerselectstore').data('selectstoremodalurl');
    if (checkout) {
        url = $('.change-shipping-method').data('selectstoremodalurl');
    }

    $.spinner().start();
    if ($('.modalSelectStore').length === 1) {
        $.spinner().stop();
        ActualizeCachedContentSelectStoreModal();
        showSelectStoreModal();
    } else {
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                $('body').append(data.selectStoreModal);
                ActualizeCachedContentSelectStoreModal();
                showSelectStoreModal();
                $.spinner().stop();
            },
        });
    }
}

/**
 * ActualizeCachedContentSelectStoreModal
 * Actualize Modal Dialog to the overwrite the cached info
 */
function ActualizeCachedContentSelectStoreModal() {
    var button = $('.headerselectstore button');
    var buttonText = button.text().trim();
    var miTiendaLabel = buttonText.includes('Código postal');

    var closeModalButton = $('.button-close-modal');
    var modalTitleSelect = $('.modal-title-select');
    var modalTitleChange = $('.modal-title-change');

    // st
    if (!miTiendaLabel) {
        closeModalButton.removeClass('d-none');
        modalTitleChange.removeClass('d-none').addClass('d-flex');
        modalTitleSelect.addClass('d-none').removeClass('d-flex');
    } else {
        closeModalButton.addClass('d-none');
        modalTitleChange.addClass('d-none').removeClass('d-flex');
        modalTitleSelect.removeClass('d-none').addClass('d-flex');
    }
}

/**
 * findStore
 * Find store by postalcode
 */
 function findStoreByPostalCode() {
    var url = $('#btn-findByPostalCodeDelivery').data('action');
    var previousUrl = window.location.href;
    var data = {
        postalcode: $('#select-store-postalcode-delivery').val()
    };
    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        data: data,
        dataType: 'json',
        success(data) {
            if (data.success) {
                $('.no-store-found').addClass('d-none');
                $.spinner().stop();
                //As a URL to delete searchParams
                var urlLink = new URL($('.select-store-popup-link-delivery').attr('href'), window.location.origin);
                urlLink.searchParams.delete('id');
                urlLink.searchParams.delete('previousUrl');
                urlLink.searchParams.set('id', data.id);
                urlLink.searchParams.set('previousUrl', previousUrl.toString());
                $('.select-store-popup-link-delivery').attr('href', urlLink.toString());
                $('.find-postal-code-delivery').removeClass('d-none');
                $('.data-store-delivery').html(data.name + ": " + data.address);
                $('.select-store-popup-delivery').removeClass('d-none');
            } else {
                $('.no-store-found').removeClass('d-none');
                $('.select-store-popup-delivery').addClass('d-none');
                $.spinner().stop();
            }
        },
    });
}

/**
 * Handle store selection logic
 * @param {Object} selectedOption - The selected option element
 * @param {string} previousUrl - The URL of the previous page
 */
function handleStoreSelection(selectedOption, previousUrl) {
    var $button = $('.select-store-popup-link-pickup');
    if (!selectedOption.attr('value')) {
        $button.addClass('disabled'); // Deshabilitar botón
    } else {
        $button.removeClass('disabled'); // Habilitar botón
        var selectedStoreId = selectedOption.val();
        var urlLink = new URL($button.attr('href'), window.location.origin);
        urlLink.searchParams.set('id', selectedStoreId);
        urlLink.searchParams.set('previousUrl', previousUrl);
        $button.attr('href', urlLink.toString());
        $button.attr('data-storeid', selectedStoreId);
    }
}

/**
 * findStores List
 * Find stores list by postalcode
 */
function findStoresListByPostalCode() {
    var url = $('#btn-findByPostalCodePickup').data('action');
    var previousUrl = window.location.href;
    var data = {
        postalcode: $('#select-store-postalcode-pickup').val()
    };
    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        data: data,
        dataType: 'json',
        success(data) {
            if (data.success) {
                $('.no-store-found').addClass('d-none');
                $('.find-postal-code-pickup').removeClass('d-none');
                $.spinner().stop();

                // Limpiamos opciones anteriores en el <select>
                var $storeList = $('#store-list-pickup');
                $storeList.empty();
                $storeList.append('<option value="">Selecciona una tienda</option>'); // Aseguramos un valor vacío para la opción predeterminada
                $('.select-store-popup-link-pickup').addClass('disabled'); // Botón deshabilitado por defecto

                // Agregar opciones de tiendas si las hay
                data.stores.forEach(function (store, index) {
                    var optionText = `${store.name}: ${store.address1}`;
                    optionText = optionText.substring(0, 50) + (optionText.length > 50 ? '...' : ''); // Substring para evitar desbordamiento del select
                    var optionElement = '<option value="' + store.ID + '"' + (index === 0 ? ' selected' : '') + '>' + optionText + '</option>';
                    $storeList.append(optionElement);
                });

                // Aplicar lógica inicial para el botón con la opción seleccionada
                handleStoreSelection($storeList.find(':selected'), previousUrl);

                // En caso de cambios en la selección llamamos al handler
                $storeList.off('change').on('change', function () {
                    var selectedOption = $(this).find(':selected');
                    handleStoreSelection(selectedOption, previousUrl);
                });

                $('.select-store-popup-pickup').removeClass('d-none');

            } else {
                $('.no-store-found').removeClass('d-none');
                $('.select-store-popup').addClass('d-none');
                $.spinner().stop();
            }
        },
        error() {
            $('.no-store-found').removeClass('d-none');
            $.spinner().stop();
        }
    });
}

/**
 * Helper function to close a specific popover
 * @param {jQuery} $popoverTarget - El popover a cerrar
 */
function closePopover($popoverTarget) {
    $popoverTarget.removeClass('show');
    $('.navbar-modal-backdrop.navbar-menu-fader').remove();
    $popoverTarget.closest('.user').attr('aria-expanded', 'false');
}

$(document).ready( function () { 
    $(document).on('click','.change-shipping-method', function (e) { 
        e.stopPropagation();

        if ($('.popover.show').length) {
            closePopover($('.popover.show')); // Cerramos cualquier popover que esté abierto
        }

        if ($('.modalSelectStore').length === 1){
            ActualizeCachedContentSelectStoreModal();
            showSelectStoreModal();
        } else {
            drawSelectStoreModal(true);
            ActualizeCachedContentSelectStoreModal();
            showSelectStoreModal();
        }

        if ($('.menu-toggleable-left').hasClass('in')) {
            // Close mobile-menu version if active
            // e.preventDefault();
            $('.navbar-nav').find('.top-category').detach();
            $('.navbar-nav').find('.nav-menu').detach();
            $('.navbar-nav').find('.show').removeClass('show');
            $('.menu-toggleable-left').removeClass('in');
    
            $('.main-menu').siblings().attr('aria-hidden', 'false');
            $('header').siblings().attr('aria-hidden', 'false');
    
            $('.modal-background').hide();
            $('.navbar-toggler').show();
        } else {
            // Close all modals to hide desktop modal
            $('.popover').removeClass('show');
        }

    })
    $(document).on('click','.selectStoreModalClose', function () {
        setTimeout(() => {
            $('#selectStoreModal').removeClass('d-flex');
        }, 500);
    })
});

module.exports = {
    init() {
        // Case of storeSelector disfuntion -> verifie the store id in the isml element is OK
        var preferredstoreid = $('.selectStoreHeader').data('preferredstoreid');
        var isPreferredStoreIdValid = typeof preferredstoreid === 'string' && /^[0-9]+$/.test(preferredstoreid);
        // (search-results OR product-detail OR homepage) AND (.headerselectstore se encuentra) AND (.headerselectstore se encuentra con el data)
        if (
            (($('.search-results').length === 1) || ($('.product-detail').length === 1) || ($('#homepage').length === 1))
            && 
            ((($('.headerselectstore').length !== 0) && $('.headerselectstore').data('showstoreselectormodal')) || isPreferredStoreIdValid)
            )
        {
            drawSelectStoreModal();
        }
    },
    showSelectStoreModal(show) {
        //Métodos showSelectStoreModal y showSelectStoreModalCheckout comentados
        // por error de duplicar modal de cambio de tienda
        //if (show) {
        //    showSelectStoreModal();
        //}
    },
    findStoreByPostalcode: function () {
        // Delivery
        $('body').on('click', '#btn-findStoreByPostalCodeDelivery', function (e) {
            $('.select-store-popup-pickup').addClass('d-none');
            $('.select-store-popup-delivery').addClass('d-none');
            $('.find-postal-code-pickup').addClass('d-none');
            $('.find-postal-code').removeClass('d-none');
        });
        $('body').on('click', '#btn-findByPostalCodeDelivery', function (e) {
            findStoreByPostalCode();
        });
        $('body').on('keypress', '#select-store-postalcode-delivery', function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();
                $('#btn-findByPostalCodeDelivery').click();
            }
        });
        
        // Pickup
        $('body').on('click', '#btn-findStoreByPostalCodePickup', function (e) {
            $('.select-store-popup-delivery').addClass('d-none');
            $('.select-store-popup-pickup').addClass('d-none');
            $('.find-postal-code').addClass('d-none');
            $('.find-postal-code-pickup').removeClass('d-none');
        });
        $('body').on('keypress', '#select-store-postalcode-pickup', function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();
                $('#btn-findByPostalCodePickup').click();
            }
        });
        $('body').on('click', '#btn-findByPostalCodePickup', function (e) {
            findStoresListByPostalCode();
        });


    },
    showSelectStoreModalCheckout: function () {
        //Métodos showSelectStoreModal y showSelectStoreModalCheckout comentados
        // por error de duplicar modal de cambio de tienda
        //$('body').on('click', '.change-shipping-method', function (e) {
        //    drawSelectStoreModal(true);
        //});
    }
};
