
const cart = require('../cart/cart');
const { saveUrlInSessionStorage } = require('../../js/redirectUrl');

let updateMiniCart = true;


/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid'),
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(() => {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}
function displayMessageWishlist(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);


    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    const $targetElement = $(`a[data-pid="${data.pid}"]`).closest('.product-info').find('.remove-product');
    const itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid'),
    };
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        const url = $(this).attr('href');
        const pid = $(this).data('pid');
        let optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        let optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: {
                pid,
                optionId,
                optionVal,
            },
            success(data) {
                displayMessageWishlist(data);
            },
            error(err) {
                displayMessageWishlist(err);
            },
        });
    });
}

$(document).on('click', '#botonPago', function(event) {
    var checkoutUrl = $(this).attr('href');
    saveUrlInSessionStorage(checkoutUrl);
})

module.exports = function () {
    cart();
    moveToWishlist();
    $('.minicart').on('count:update', (event, data) => {
        if (data && $.isNumeric(data.quantityTotal)) {
            $('.minicart .minicart-quantity .text').text(data.quantityTotal);

            //.minicart-subtotal actualization
                var subTotalIncoming = data.totals.subTotal;
                var subTotalString = String(subTotalIncoming);
                var cleanedSubtotal = subTotalString
                    .replace(/[^0-9.,]/g, '')
                    .replace(',', '.');
                var subTotalValue = parseFloat(cleanedSubtotal);
                var formattedSubtotal = '';
                if (!isNaN(subTotalValue)) {
                    formattedSubtotal = new Intl.NumberFormat('es-ES', {
                        style: 'currency',
                        currency: 'EUR',
                    }).format(subTotalValue);
                    formattedSubtotal = formattedSubtotal.replace(/\s/g, '');
                } else {
                    console.error("El subtotal no es un número válido:", cleanedSubtotal);
                }
            $('.minicart .minicart-subtotal .text').text(formattedSubtotal);
            
            $('.minicart .minicart-link').attr({
                'aria-label': data.minicartCountOfItems,
                title: data.minicartCountOfItems,
            });
            
            var prodsQuantity = 0;
            if (data && data.items) {
                const items = data.items;

                for (var i = 0; i < items.length; i++) {
                    if (items[i].unitData && items[i].unitData.unit === 'kg') {
                        prodsQuantity++;
                    } else {
                        prodsQuantity += items[i].quantity;
                    }
                }
                $('.minicart .minicart-quantity .text').text(prodsQuantity);
                $('.minicart .minicart-subtotal .text').text(formattedSubtotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': prodsQuantity,
                    title: prodsQuantity,
                });
            } else if(data && data.cart && data.cart.items) {
                const items = data.cart.items;

                for (var i = 0; i < items.length; i++) {
                    if (items[i].unitData && items[i].unitData.unit === 'kg') {
                        prodsQuantity++;
                    } else {
                        prodsQuantity += items[i].quantity;
                    }
                }
                $('.minicart .minicart-quantity .text').text(prodsQuantity);
                $('.minicart .minicart-subtotal .text').text(formattedSubtotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': prodsQuantity,
                    title: prodsQuantity,
                });
            }

            // only needed when new products are added, on plp qty increase this isn't needed
            if (data && data.cart && data.cart.items) {
                const itemsquantities = [];
                const { items } = data.cart;

                items.forEach((item) => {
                    itemsquantities.push({ id: item.id, qty: item.quantity });
                });
                $('.cart-json').data('cart', { itemsquantities });
            }
        }
    });

    /**
     * Helper function to close a specific popover
     * @param {jQuery} $popoverTarget - El popover a cerrar
     */
    function closePopover($popoverTarget) {
        $popoverTarget.removeClass('show');
        $('.navbar-modal-backdrop.navbar-menu-fader').remove();
        $popoverTarget.closest('.minicart').attr('aria-expanded', 'false');
    }

    /**
     * Helper function to open a specific popover
     * @param {jQuery} $popoverTarget - popover a abrir
     */
    function openPopover($popoverTarget) {
        if ($('.popover.show').length) {
            closePopover($('.popover.show')); // Cerramos cualquier popover que esté abierto
        }
        if (!$('.navbar-modal-backdrop.navbar-menu-fader').length) {
            $('<div class="navbar-modal-backdrop navbar-fade navbar-menu-fader"></div>').appendTo('body');
        }
        $popoverTarget.addClass('show');
        $popoverTarget.closest('.minicart').attr('aria-expanded', 'true');
    }

    // Actualiza el contenido del minicart a la carga
    $(document).ready(() => {
        const url = $('.minicart').data('action-url');
        const count = parseInt($('.minicart .minicart-quantity .text').text(), 10);
    
        if (count !== 0) {
            // Verificar que no hay modales presentes en pantalla
            if ($('.modal.fade.show').length > 0) {
                return;
            }
    
            $.get(url, (data) => {
                $('.minicart .popover').html(data);
                updateMiniCart = false;
            });
        }
    });

    $('.nav-button-minicart').on('click touchstart', () => {
        if ($('.search:visible').length === 0) {
            return;
        }
        const url = $('.minicart').data('action-url');
        const count = parseInt($('.minicart .minicart-quantity .text').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            // Verificar que no hay modales presentes en pantalla
            if ($('.modal.fade.in.show').length > 0) {
                return;
            }

            if (!updateMiniCart) {
                openPopover($('.minicart .popover'));
                return;
            }

            $.get(url, (data) => {
                $('.minicart .popover').html(data); 
                updateMiniCart = false;
            });
            openPopover($('.minicart .popover'));
            return;
        }
    });

    $(document).on('click', (e) => {
        const $popoverTargetDOM = $('.nav-button-minicart .popover.popover-bottom.popover-minicart.show')[0]; // Obtener nodo DOM
        const $popoverTarget = $('.nav-button-minicart .popover.popover-bottom.popover-minicart.show');
        const target = e.target;
        const $containerCart = $('.nav-button-minicart .popover .container.cart');
    
        // Verificar si el popover está visible
        if ($popoverTargetDOM && $popoverTargetDOM.contains(target)) {
            if (
                // El clic está fuera del .nav-button-minicart
                !$('.nav-button-minicart').has(e.target).length || 
                // El clic está dentro de .nav-button-minicart pero fuera de .container.cart
                (!$containerCart.has(e.target).length)
            ) {
                closePopover($popoverTarget); // Cerrar el popover
            }
        }
    
        // Cerrar si se hace clic en el botón de cierre
        if ($(e.target).is('.close-minicart')) {
            closePopover($popoverTarget);
        }
    });

    $(document).on('click', '.close-minicart', function() {
        closePopover($('.minicart .popover'));
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', () => {
        updateMiniCart = true;
    });
    $('body').on('cart:update', () => {
        updateMiniCart = true;
    });
};
