/* eslint-disable linebreak-style */

const base = require('base/components/menu');
const keyboardAccessibilityStore = require('base/components/keyboardAccessibility');

module.exports = {
    base,
    function() {
        /**
         * Helper function to close a specific popover
         * @param {jQuery} $popoverTarget - El popover a cerrar
         */
        function closePopover($popoverTarget) {
            $popoverTarget.removeClass('show');
            $('.navbar-modal-backdrop.navbar-menu-fader').remove();
            $popoverTarget.closest('.store').attr('aria-expanded', 'false');
        }

        /**
         * Helper function to open a specific popover
         * @param {jQuery} $popoverTarget - popover a abrir
         */
        function openPopover($popoverTarget) {
            if ($('.popover.show').length) {
                closePopover($('.popover.show')); // Cerramos cualquier popover que esté abierto
            }
            if (!$('.navbar-modal-backdrop.navbar-menu-fader').length) {
                $('<div class="navbar-modal-backdrop navbar-fade navbar-menu-fader"></div>').appendTo('body');
            }
            $popoverTarget.addClass('show');
            $popoverTarget.closest('.store').attr('aria-expanded', 'true');
        }

        keyboardAccessibilityStore(
            '#navbar-store',
            {
                40() { /* down */ },
                38() { /* up */ },
                27() { closePopover($('#navbar-store .store .popover')); }, // Escape
                9() { /* tab */ },
            },
            () => $('#navbar-store .store .popover li.nav-item')
        );

        // Handle click on the navbar-store button (id)
        $('#navbar-store').on('click', () => {
            const $popoverTarget = $('#navbar-store .store .popover');
            if (!$popoverTarget.hasClass('show')) {
                openPopover($popoverTarget);
            }
        });

        // Handle CLOSE (clic fuera de navbar-store o sobre close-store)
        $(document).on('click', (e) => {
            const $popoverTarget = $('#navbar-store .store .popover');
            if ((!$('#navbar-store').has(e.target).length &&
            !$(e.target).is('.close-store')) &&
            $popoverTarget.hasClass('show')) {
                closePopover($popoverTarget);
            }
            if ($(e.target).is('.close-store')) {
                closePopover($popoverTarget); // Cerramos el popover
            }
        });

        /* Hide toggler when opening menu */
        $('.navbar-toggler').click(() => {
            $('.navbar-toggler').hide();
        });

        /* Show toggler after closing menu */
        $('.navbar-toggleable-sm .navbar .close-menu').on('click', '.close-button', () => {
            $('.navbar-toggler').show();
        });

        $('#removeProductModal').on('click', '.modal-header .close, .modal-footer button[data-dismiss="modal"]', () => {
            $('body > .modal-backdrop.fade.show, body > .modal-backdrop.fade.in').remove();
            $('#removeProductModal').removeClass('in show');
        });
    },
};
